import React, { SyntheticEvent } from "react";

import { MenuButton, HamBar } from "./style";

interface Props {
  onClick?(e: SyntheticEvent): void;
  id?: string;
  open?: boolean;
  color?: string;
}

const Hamburger: React.FC<Props> = (props) => {
  const { onClick, open, ...rest } = props;

  return (
    <MenuButton data-testid="menu-button" onClick={onClick}>
      <HamBar {...rest} data-testid={`hambar-${open}`} />
      <HamBar {...rest} data-testid={`hambar-${open}`} />
      <HamBar {...rest} data-testid={`hambar-${open}`} />
    </MenuButton>
  );
};

Hamburger.defaultProps = {
  onClick: () => {},
  id: "",
  open: false,
  color: "#0163af",
};

export default Hamburger;
