import { useContext, useEffect, useState } from "react";
import { useRaffaella } from "@eriksdigital/raffaella";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { constructShoppingUrl } from "../../utils/shopUtils";

const useShoppingCartUrl = () => {
  const [shoppingCartUrl, setShoppingCartUrl] = useState("/");
  const [salesOrganization] = useRaffaella("salesOrganization");
  const { erpSystem, language } = useContext(LanguageContext) as {
    erpSystem: any;
    language: any;
  };

  useEffect(() => {
    if (salesOrganization.data) {
      const url = constructShoppingUrl(
        salesOrganization.data,
        language,
        erpSystem
      );
      setShoppingCartUrl(url);
    }
  }, [salesOrganization, erpSystem, language]);

  return shoppingCartUrl;
};

export { useShoppingCartUrl };
