import React, { SyntheticEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Language } from "../../utils/languageUtils";
import { Box } from "@eriksdigital/atomic-ui/components";

import { Container, Color, ErrorMessage, ColorLabel } from "./styles";

interface Selected {
  value: string;
}

export interface ColorItem {
  id: string;
  label: string | null;
  value: string | null;
}

interface Props {
  colors?: string[];
  selected: Selected;
  name: string;
  error?: boolean;
  errorMessage?: string;
  onChange(color: ColorItem): void;
  onFocus(e?: React.ChangeEvent<HTMLElement>): void;
  onBlur(e?: React.ChangeEvent): void;
}

export const ColorPalette: React.FC<Props> = (props) => {
  const {
    colors,
    onChange,
    selected,
    onFocus,
    name,
    onBlur,
    error,
    errorMessage,
  } = props;
  const intl = useIntl();
  const onSelectColor = (e: SyntheticEvent, color: ColorItem) => {
    e.preventDefault();
    onChange(color);
    onFocus();
  };

  return (
    <div data-testid="color-palette">
      <ColorLabel data-testid="color-palette-label">
        <FormattedMessage
          id="form.color"
          description="label for color selection"
          defaultMessage="Color"
        />{" "}
        <Box data-testid="color-palette-selected">
          {selected?.value && `(${selected.value})`}
        </Box>
      </ColorLabel>
      <Container>
        <Color
          title={intl.formatMessage({
            id: "form.notApplicableColor",
            defaultMessage: "Not applicable",
            description: "label of not applicable color in the color picker",
          })}
          isSelected={!selected?.value}
          name={name}
          onClick={(e: SyntheticEvent) =>
            onSelectColor(e, { id: "color", label: null, value: null })
          }
          onBlur={onBlur}
          data-testid="none"
        >
          <FormattedMessage
            id="form.palette.clearColor"
            defaultMessage="X"
            description="x inside clear color swatch"
          />
        </Color>
        {!!colors &&
          colors.map((color: string) => {
            return (
              <Color
                key={color}
                title={color}
                color={color}
                name={name}
                onBlur={onBlur}
                onClick={(e: SyntheticEvent) =>
                  onSelectColor(e, { id: "color", label: color, value: color })
                }
                data-testid={color}
                isSelected={selected?.value === color}
                language={intl.locale as Language}
              />
            );
          })}
      </Container>
      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default ColorPalette;
