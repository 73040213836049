import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  AddToCart as A2C,
  LanguageContext,
  ShoppingCartContext,
  NotificationContext,
} from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { languageToCode } from "../../../../utils/languageUtils";
import { AddToCartWrapper } from "./styles";
import { ItemData } from "../index";
import { useRaffaella } from "@eriksdigital/raffaella";

export const AddToCart = ({
  data,
  position,
}: {
  data: ItemData;
  position: number;
}) => {
  const { erpSystem, language } = useContext(LanguageContext) as {
    erpSystem: any;
    language: any;
  };
  const { createNotification } = useContext(NotificationContext) as {
    createNotification: any;
  };
  const { fetchShoppingCart } = useContext(ShoppingCartContext) as {
    fetchShoppingCart: any;
  };
  const { sendAddToCartEvent } = useContext(GTMContext);
  const [addToCart, makeResourceCall] = useRaffaella("addToCart");
  const [itemQuantity, setItemQuantity] = useState(1);
  const [itemClicked, setItemClicked] = useState<string | null>(null);

  const handleAddToCartSuccess = useCallback(() => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${data.erpPartNumber}`,
        variant: "success",
        message: (
          <FormattedMessage
            id="add-to-cart.success"
            defaultMessage="The product was added to your shopping cart"
            description="Item is succesfully added to the shoping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
    setItemClicked(null);
  }, [data.erpPartNumber, fetchShoppingCart, createNotification]);

  const handleAddToCartError = useCallback(() => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${data.erpPartNumber}`,
        variant: "error",
        message: (
          <FormattedMessage
            id="add-to-cart.error"
            defaultMessage="Our apologies! Something went wrong when adding the product to your shopping cart. Please contact the ERIKS support team."
            description="Item was not added to the shopping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
    setItemClicked(null);
  }, [data.erpPartNumber, createNotification, fetchShoppingCart]);

  useEffect(() => {
    if (
      !addToCart.isLoading &&
      !!addToCart.data &&
      !addToCart.error &&
      itemClicked === data.erpPartNumber
    ) {
      handleAddToCartSuccess();
    }
  }, [
    addToCart.isLoading,
    addToCart.error,
    addToCart.data,
    handleAddToCartSuccess,
    itemClicked,
    data.erpPartNumber,
  ]);

  useEffect(() => {
    if (
      !addToCart.isLoading &&
      !!addToCart.data &&
      addToCart.error &&
      itemClicked === data.erpPartNumber
    ) {
      handleAddToCartError();
    }
  }, [
    addToCart.isLoading,
    addToCart.error,
    addToCart.data,
    handleAddToCartError,
    itemClicked,
    data.erpPartNumber,
  ]);

  useEffect(() => {
    if (!addToCart.isLoading && !!addToCart.data && !addToCart.error) {
      sendAddToCartEvent &&
        sendAddToCartEvent({
          itemName: data.description,
          itemId: data.partNumber,
          price: data.priceRaw,
          itemBrand: data.brand,
          itemStockStatus: data?.stockStatus?.available,
          index: position,
          materialNumber: data.erpPartNumber,
          quantity: itemQuantity,
        });
    }
  }, [
    data.description,
    data.partNumber,
    data.priceRaw,
    data.brand,
    data?.stockStatus?.available,
    data.erpPartNumber,
    position,
    itemQuantity,
    addToCart.isLoading,
    addToCart.error,
    addToCart.data,
    sendAddToCartEvent,
  ]);

  const handleAddToCart = async (quantity: number) => {
    setItemQuantity(quantity);
    setItemClicked(data.erpPartNumber);

    makeResourceCall &&
      makeResourceCall({
        apiKey: "addToCart",
        reqData: {
          locale: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          erpPartNo: data.erpPartNumber,
          quantity,
          targetSystem: "SEL_ORI",
        },
      });
  };

  return (
    <AddToCartWrapper>
      <A2C
        table
        id={data.erpPartNumber}
        data-testid={data.erpPartNumber}
        onAdd={handleAddToCart}
        quantityInitialValue={1}
        addToCartRound
        quantityRounded
      />
    </AddToCartWrapper>
  );
};
