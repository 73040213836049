import React, { FC, useContext, useEffect, useState } from "react";
import {
  H4,
  P,
  Box,
  Icon,
  Button,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import {
  EmailIcon,
  WarningIcon,
  PhoneIcon,
} from "@eriksdigital/atomic-ui/components/Icons";
import {
  Wrapper,
  NoResultHeading,
  List,
  ContactItem,
  SorryContainer,
  NoResultsTextWrapper,
  IconContainer,
} from "./styles";
import { useIntl, FormattedMessage } from "react-intl";
import { Result } from "../../containers/ORingSelector";

const NoResults: FC<{ data: Result; resetForm: () => void }> = ({
  data,
  resetForm,
}) => {
  const { erpSystem } = useContext(LanguageContext) as { erpSystem: any };
  const [noResultsContact, setNoResultsContact] = useState<{
    email: string;
    phone: string;
  }>({ email: "", phone: "" });
  const intl = useIntl();
  useEffect(() => {
    //on mobile scroll a bit so the results container is visible
    // 450 is temporary until the diameter object is transformed
    // into proper string, should be 400
    if (window.innerWidth < 450) {
      window.scrollBy({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    switch (erpSystem) {
      case "nl": {
        setNoResultsContact({
          phone: "+31 88 855 80 66",
          email: "oringen@eriks.nl",
        });
        break;
      }
      case "ch": {
        setNoResultsContact({
          phone: "+41 848 111 333",
          email: "dichtungen-ch@maagtechnic.com",
        });
        break;
      }
      case "be": {
        setNoResultsContact({
          phone: "+32 (0)3 829 27 10",
          email: "seals@eriks.be",
        });
        break;
      }
      case "uk": {
        setNoResultsContact({
          phone: "+44(0)1384 276662",
          email: "sealingandpolymer@eriks.co.uk",
        });
        break;
      }
      case "fr": {
        setNoResultsContact({
          phone: "",
          email: "eriks.elastomeres@eriks.fr",
        });
        break;
      }
      default: {
        setNoResultsContact({
          phone: "",
          email: "",
        });
        break;
      }
    }
  }, [erpSystem]);
  return (
    <>
      <Wrapper>
        <SorryContainer>
          <IconContainer>
            <Icon as={WarningIcon} color="#005ca9" size="sz56" />
            <H4>
              <FormattedMessage
                id="no-results.title"
                defaultMessage="We're sorry!"
                description="Titile of the no results section"
              />
            </H4>
          </IconContainer>
          <NoResultsTextWrapper data-testid="o-ring-no-results-message">
            <P>
              <FormattedMessage
                id="no-results.text"
                defaultMessage="We do not have any o-rings in our online assortment that match -"
                description="intro text for no results"
              />{" "}
              <span style={{ fontWeight: "bold" }}>
                {Object.entries(data).map(([key, value]) => {
                  return (
                    <React.Fragment key={key}>
                      {key} : {value}{" "}
                    </React.Fragment>
                  );
                })}
              </span>
            </P>
          </NoResultsTextWrapper>
        </SorryContainer>
      </Wrapper>
      <Wrapper>
        <NoResultsTextWrapper>
          <Box>
            <NoResultHeading>
              <FormattedMessage
                id="no-result.search-tips"
                defaultMessage="Search tips"
                description="Serch tips heading"
              />
            </NoResultHeading>
            <List>
              <li>
                <FormattedMessage
                  id="o-result.search-tip-1"
                  defaultMessage="Try a different size"
                  description="Search tip 1"
                />
              </li>
              <li>
                <FormattedMessage
                  id="no-result.search-tip-2"
                  defaultMessage="Try a different material "
                  description="Search tip 2"
                />
              </li>
            </List>
            <Button
              id="no-result-clear"
              data-testid="clear-form"
              data
              onClick={resetForm}
              size="regular"
              stretch="auto"
              text={intl.formatMessage({
                id: "no-results.button.clear",
                defaultMessage: "Clear all fields and search again",
                description: "Label for no results clear button",
              })}
            />
          </Box>
        </NoResultsTextWrapper>
      </Wrapper>
      <Wrapper>
        <NoResultsTextWrapper>
          <Box>
            <NoResultHeading>
              <FormattedMessage
                id="no-result.still-need-help"
                defaultMessage="Need help?"
                description="No results heading"
              />
            </NoResultHeading>

            <ContactItem>
              <Icon as={EmailIcon} color="#005ca9" size="sz16" />
              <p>{noResultsContact.email}</p>
            </ContactItem>

            <ContactItem>
              <Icon as={PhoneIcon} color="#005ca9" size="sz16" />
              <p>{noResultsContact.phone}</p>
            </ContactItem>
          </Box>
        </NoResultsTextWrapper>
      </Wrapper>
    </>
  );
};

export default NoResults;
