import "./polyfills";
/**
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 * CREATE REACT APP REQUIRES THE IE11 POLYFILL TO BE THE FIRST LINE OF SRC/INDEX.TSX
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/GlobalStyles";
import { NotificationProvider } from "@eriksdigital/atomic-ui/components";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { TranslationProvider } from "./providers/TranslationProvider";
import { GTMProvider } from "@eriksdigital/gs-ga4";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

const consoleError = console.error.bind(console);
console.error = (message: { code: string }, ...args: any) => {
  if (message.code === "MISSING_TRANSLATION") {
    return;
  }
  consoleError(message, ...args);
};

const gtmIdEnv =
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? "test"
    : process.env.REACT_APP_ENVIRONMENT;

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme}>
        <TranslationProvider>
          <GTMProvider env={gtmIdEnv}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </GTMProvider>
        </TranslationProvider>
      </ThemeProvider>
    </HelmetProvider>
    <GlobalStyles />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
