import { SalesOrganization } from "../components/Header";
import { Language, languageToCode } from "./languageUtils";

export type EnvironmentsType = "dev" | "test" | "acc" | "prod";

const environments: { [key in EnvironmentsType]: string } = {
  dev: "dev.",
  test: "dev.",
  acc: "test.",
  prod: "",
};

type ShopNames = "nl" | "be" | "lu" | "fr" | "ch" | "uk" | "de";

const shopNames: { [key in ShopNames]: string } = {
  nl: "shop.eriks.nl/",
  be: "shop.eriks.be/",
  lu: "shop.eriks.lu/",
  fr: "shop.eriks.fr/",
  ch: "shop.maagtechnic.ch/",
  uk: "shop.eriks.co.uk/",
  de: "shop.eriks.de/",
};

const oRingUrlPerLanguage: { [key in string]: string } = {
  nl: "afdichtingstechniek-o-ringen-en-toebehoren-o-ringen/",
  fr:
    "technologie-d-etancheite-joints-toriques-o-rings-et-accessoires-joint-torique-o-rings/",
  en: "sealing-equipment-o-rings-and-accessories-o-rings/",
  de: "dichtungstechnik-o-ringe-und-zubehoer-o-ringe/",
};

export const constructUrl = (
  environment: EnvironmentsType = "test",
  erpSystem: ShopNames = "nl",
  language: string = "nl"
) => {
  return `https://${environments[environment]}${shopNames[erpSystem]}${language}/${oRingUrlPerLanguage[language]}`;
};

export const constructShoppingUrl = (
  data: SalesOrganization[],
  language: Language,
  erpSystem: string
) => {
  if (!data) return "/";
  const salesOrganization = data.find(
    (org) => org.country === erpSystem.toUpperCase()
  );
  if (!salesOrganization) return "/";
  return `${
    salesOrganization.shop_url
  }ShoppingCartDisplay?catalogId=1000&langId=${languageToCode(
    language
  )}&storeId=${salesOrganization.store_id}`;
};
