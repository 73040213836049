import React, { useEffect } from "react";
import { Placeholder } from "@eriksdigital/atomic-ui/components";
import Item from "./Item";
import { Wrapper, LoadingWrapper } from "./styles";
import { statuses } from "./StockInformation";

export interface Attribute {
  featureName: string;
  value: string;
}

export interface Media {
  fullImage: string;
  mediaType: string;
}

export interface ItemData {
  isPriceLoading: boolean;
  partNumber?: string;
  erpPartNumber: string;
  description: string;
  available?: string;
  deliveryDate?: string;
  brand?: string;
  price?: string;
  priceRaw?: number;
  currency?: string;
  itemLink?: string;
  attributes?: Attribute[];
  medias?: Media[];
  stockStatus: { available: keyof statuses; deliveryDate: string };
}

interface Props {
  items: ItemData[];
  loading?: boolean;
  stockLoading: boolean;
}

const Results: React.FC<Props> = (props: Props) => {
  const { items, loading } = props;
  useEffect(() => {
    if (items?.length && !loading && items !== null) {
      //on mobile scroll a bit so the results container is visible
      if (window.innerWidth < 400) {
        window.scrollBy({
          top: 200,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [items, loading]);

  return (
    <Wrapper data-testid="results">
      {loading && (
        <LoadingWrapper data-testid="results-loading">
          <Placeholder type="product" />
          <Placeholder type="product" />
        </LoadingWrapper>
      )}
      {!loading &&
        items.map((item, index) => {
          return (
            <Item
              key={item.erpPartNumber}
              erpPartNumber={item.erpPartNumber}
              position={index + 1}
              data-testid={`result-${index}`}
            />
          );
        })}
    </Wrapper>
  );
};

export default Results;
