import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Header from "./components/Header/index";
import { useBasenamePath } from "./utils/useBasenamePath";
import { Main } from "./App.styled";
import ORingSelector from "./containers/ORingSelector";
import { NotificationCenter } from "./components/NotificationCenter";
import { ResourcesProvider } from "@eriksdigital/raffaella";
import { config } from "./resourcesConfig";
import apiPath from "./utils/apiPath";

const App: React.FC = () => {
  const basenamePath = useBasenamePath();

  return (
    <Router>
      <Fragment>
        <ResourcesProvider config={config} apiPath={apiPath()}>
          <Header />
          <Main>
            <Switch>
              <Route exact path={basenamePath} component={ORingSelector} />
              <Redirect from="*" to={basenamePath} />
            </Switch>
          </Main>
          <NotificationCenter />
        </ResourcesProvider>
      </Fragment>
    </Router>
  );
};

export default App;
