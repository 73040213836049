import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext, Logo, Box } from "@eriksdigital/atomic-ui/components";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel,
  StyledSelect,
} from "./style";
import { ENVIRONMENT } from "../../constants";
import { constructUrl, EnvironmentsType } from "../../utils/shopUtils";
import Hamburger from "../Hamburger";
import ShoppingCart from "../ShoppingCart";
import { useBasenamePath } from "../../utils/useBasenamePath";
import {
  LocalesDropdownItem,
  pimLocalesDropdown,
} from "../../utils/languageUtils";
import LogInStatus from "../LogInStatus";
import { useRaffaella } from "@eriksdigital/raffaella";

export type SalesOrganization = {
  sales_organization: string;
  shop_url: string;
  country: string;
  store_id: number;
  guided_selling_base_url: string;
};

const Header: React.FC = () => {
  const { language, setLanguage, erpSystem } = useContext(LanguageContext) as {
    language: string;
    setLanguage: (language: string) => void;
    erpSystem: any;
  };
  const [isMenuOpen, toggleMenu] = useState(false);
  const menu = useRef<HTMLDivElement>(null);
  const [languageDropdown, updateLanguageDropdown] = useState<
    LocalesDropdownItem[]
  >(Object.values(pimLocalesDropdown));
  const basenamePath = useBasenamePath();

  const history = useHistory();

  const [salesOrganization, makeResourceCall] = useRaffaella(
    "salesOrganization"
  );
  const [availableLanguages] = useRaffaella("availableLanguages");
  const [user] = useRaffaella("user");

  useEffect(() => {
    if (makeResourceCall) {
      makeResourceCall({ apiKey: "salesOrganization" });
    }
  }, [makeResourceCall]);

  useEffect(() => {
    if (salesOrganization.data) {
      const currentStore: SalesOrganization = salesOrganization.data.find(
        (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
      );
      makeResourceCall &&
        makeResourceCall({
          apiKey: "availableLanguages",
          reqData: {
            storeUrl: currentStore.shop_url.slice(0, -1),
            storeId: currentStore.store_id,
          },
        });
    }
  }, [salesOrganization, makeResourceCall, erpSystem]);

  useEffect(() => {
    if (availableLanguages.data) {
      const newDropdown = availableLanguages.data.map(
        (lang: { countryCode: "string" }) =>
          pimLocalesDropdown[lang.countryCode]
      );
      updateLanguageDropdown(newDropdown);
      if (
        !newDropdown.find(
          (locale: LocalesDropdownItem) => locale.value === language
        )
      ) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(basenamePath);
    setLanguage(value);
  };

  const handleMenuToggle = (): void => toggleMenu(!isMenuOpen);

  const handleClickOutside = (e: MouseEvent): void => {
    if (menu.current && menu.current.contains(e.target as HTMLElement)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="o-ring-header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            data-testid="o-ring-logo-container"
            href={`${constructUrl(
              ENVIRONMENT as EnvironmentsType,
              erpSystem,
              language
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo
              data-testid="o-ring-logo-image"
              inverted={null}
              width="sz208"
              height="64"
            />
          </LogoWrapper>
          <Box display="flex" alignItems="center">
            {user.data?.loggedIn && <ShoppingCart />}
            <Hamburger
              id="menu-button"
              data-testid="o-ring-hamburger-menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
            {user.data && <LogInStatus user={user.data} />}
          </Box>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="o-ring-menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="o-ring-language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang) => {
                return (
                  <option
                    data-testid={`o-ring-language-option-${lang.label}`}
                    value={lang.value}
                    key={lang.value}
                  >
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
