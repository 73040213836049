import React, { useContext, useEffect } from "react";
import {
  Icon,
  Span,
  Link,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import {
  CartIcon,
  LoadingIcon,
} from "@eriksdigital/atomic-ui/components/Icons";
import { useRaffaella } from "@eriksdigital/raffaella";
import { languageToCode } from "../../utils/languageToCode";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { IconWrapper, ItemCountCircle } from "./style";
import { useShoppingCartUrl } from "../../hooks/useShoppingCartUrl";

const ShoppingCart: React.FC = () => {
  const shoppingCartUrl = useShoppingCartUrl();
  const [shoppingCart, makeResourceCall] = useRaffaella("shoppingCart");
  const { language, erpSystem } = useContext(LanguageContext) as {
    language: any;
    erpSystem: any;
  };

  const { isLoading, data } = shoppingCart;

  const x_totalOrderItemCount = data?.x_totalOrderItemCount ?? 0;

  useEffect(() => {
    if (makeResourceCall && language && erpSystem) {
      makeResourceCall({
        apiKey: "shoppingCart",
        reqData: {
          locale: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_ORI",
        },
      });
    }
  }, [makeResourceCall, language, erpSystem]);

  return (
    <div id="navbar-cart-icon">
      <Link
        href={shoppingCartUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle isLargeAmount={x_totalOrderItemCount > 99}>
            {isLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <Span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </Span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </Link>
    </div>
  );
};

export default ShoppingCart;
