import React, { useState, useContext, useEffect } from "react";
import { H2, Box, LanguageContext } from "@eriksdigital/atomic-ui/components";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import ORingSelectorForm, { IValues } from "./ORingSelectorForm/index";
import Results, { ItemData } from "./Results";
import { ContainerWrapper, Content } from "./styles";
import { ResultActions, ResultActionsPagination } from "./Results/styles";
import ShowMore from "./Results/ShowMore";
import NoResults from "../../components/NoResult";
import { transformData } from "../../utils/json-helpers";
import { useRaffaella, useClearResource } from "@eriksdigital/raffaella";
import { languageToCode } from "../../utils/languageToCode";
import { StockLevel } from "../../resourcesConfig";
import { PriceItem } from "../../hooks/useItemWithPriceAndStock/useItemWithPriceAndStock";

export interface Attribute {
  value: string;
  internalDiameter?: number;
  crossSection?: number;
}

export interface OptionsList {
  [key: string]: Attribute[] | number;
}

export interface ResultsState {
  results: ItemData[];
  shouldSendViewEvent: boolean;
  isLoading: boolean;
}

export type Result = { [key: string]: any };

const ORingSelector = () => {
  const intl = useIntl();
  const LIMIT_PER_REQUEST = 5;
  const { language, erpSystem } = useContext(LanguageContext) as {
    language: any;
    erpSystem: any;
  };
  const [items, makeResourceCall] = useRaffaella("items");
  const [user] = useRaffaella("user");
  const [attributesResource] = useRaffaella("attributes");
  const [stockLevels] = useRaffaella("stockLevels");
  const [price] = useRaffaella("price");
  const clearItems = useClearResource("items");
  const [attributes, setAttributes] = useState<OptionsList>({});
  const [formData, setFormData] = useState<Result>({});
  const [currentLimitResults, setCurrentLimitResults] = useState(
    LIMIT_PER_REQUEST
  );

  const shouldSendViewEvent =
    !(
      items.isLoading ||
      stockLevels.isLoading ||
      price.isLoading ||
      user.isLoading
    ) &&
    items.data?.items?.length &&
    stockLevels.data?.length === items.data?.items?.length &&
    price.data?.length === items.data?.items?.length;

  const {
    sendProcessStartEvent,
    isReadyToSendEvents,
    sendPageViewEvent,
    sendViewItemEvent,
  } = useContext(GTMContext);

  useEffect(() => {
    if (
      isReadyToSendEvents &&
      sendPageViewEvent &&
      user.data &&
      !user.isLoading
    ) {
      const {
        customerNumber,
        organizationName,
        userSalesChannel,
        userType,
        userId,
        userSegment,
        loggedIn,
      } = user.data;
      const userString = userId ? userId.toString() : "";
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber,
        organizationName,
        userSalesChannel,
        userType,
        userId: userString,
        userSegment,
        loggedIn,
      });
    }
  }, [isReadyToSendEvents, sendPageViewEvent, erpSystem, user]);

  useEffect(() => {
    if (isReadyToSendEvents && sendProcessStartEvent) {
      sendProcessStartEvent({ processStepName: "1_chooseproductdetails" });
    }
  }, [sendProcessStartEvent, isReadyToSendEvents]);

  const filterQuery = {
    lang: language,
    erpSystem: erpSystem.toUpperCase(),
    limit: currentLimitResults,
  };

  const onSubmit = (form: IValues) => {
    const data = transformData(form);
    setFormData(data);
    setCurrentLimitResults(LIMIT_PER_REQUEST);
    makeResourceCall &&
      makeResourceCall({
        apiKey: "items",
        reqData: { ...filterQuery, limit: LIMIT_PER_REQUEST, ...data },
      });
  };

  useEffect(() => {
    makeResourceCall &&
      makeResourceCall({
        apiKey: "user",
        reqData: {
          languageId: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_ORI",
        },
      });
  }, [makeResourceCall, language, erpSystem]);

  useEffect(() => {
    if (attributesResource.data) {
      setAttributes(attributesResource.data);
    }
  }, [attributesResource]);

  useEffect(() => {
    makeResourceCall &&
      makeResourceCall({
        apiKey: "attributes",
        reqData: { lang: language, erpSystem: erpSystem.toUpperCase() },
      });
  }, [makeResourceCall, language, erpSystem]);

  useEffect(() => {
    if (shouldSendViewEvent && sendViewItemEvent) {
      sendViewItemEvent(
        items.data?.items?.map((item: ItemData, index: number) => {
          const stockStatus = stockLevels.data.find(
            (stock: StockLevel) => stock.number === item.erpPartNumber
          );
          const itemPrice = price.data.find(
            (priceItem: PriceItem) => priceItem.number === item.erpPartNumber
          );
          return {
            itemName: item.description,
            price: user.data.loggedIn ? itemPrice.priceRaw : "",
            itemId: item.partNumber,
            itemBrand: item.brand,
            itemStockStatus: stockStatus.available,
            index: index + 1,
            materialNumber: item.erpPartNumber,
          };
        })
      );
    }
  }, [shouldSendViewEvent, items, sendViewItemEvent, user, price, stockLevels]);

  const handleChangeResultsShowMore = (currentLimitResults: number) => {
    makeResourceCall &&
      makeResourceCall({
        apiKey: "items",
        reqData: { ...filterQuery, limit: currentLimitResults, ...formData },
      });
    setCurrentLimitResults(currentLimitResults);
  };

  const showNoResults = (submitFailed: boolean, formInputData: Result) => {
    setFormData(transformData(formInputData));
  };

  const resetFormhandler = () => {
    window.Form.restart();
    clearItems();
  };

  return (
    <Box marginTop="sp24" marginBottom="sp24">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "title",
            description: "title of the application",
            defaultMessage: "O-ring Selector",
          })}
        </title>
      </Helmet>
      <H2 data-test-id="title">
        <FormattedMessage
          id="title"
          defaultMessage="O-ring Selector"
          description="title of the application"
        />
      </H2>
      <Box marginTop="sp16" marginBottom="sp16">
        <ContainerWrapper>
          <Content>
            <ORingSelectorForm
              data-test-id="o-ring-selector-form"
              onSubmit={onSubmit}
              attributes={attributes}
              showNoResults={showNoResults}
              resetForm={resetFormhandler}
            />
          </Content>
        </ContainerWrapper>
        {items?.data?.items?.length > 0 && !items.isLoading && !items.error && (
          <ResultActions>
            <Results
              data-test-id="o-ring-results"
              items={items?.data?.items}
              stockLoading={stockLevels.isLoading}
            />
            {items?.data?.hasMore && (
              <ResultActionsPagination>
                <ShowMore
                  data-test-id="o-ring-show-more"
                  loadMore={() =>
                    handleChangeResultsShowMore(
                      currentLimitResults + LIMIT_PER_REQUEST
                    )
                  }
                  limit={currentLimitResults}
                />
              </ResultActionsPagination>
            )}
          </ResultActions>
        )}
        {items?.data?.items?.length === 0 &&
          !items?.isLoading &&
          !items?.error && (
            <NoResults
              data-test-id="o-ring-no-results"
              data={formData}
              resetForm={resetFormhandler}
            />
          )}
      </Box>
    </Box>
  );
};

export default ORingSelector;
