export interface LocalesDropdown {
  [key: string]: LocalesDropdownItem;
}

export interface LocalesDropdownItem {
  value: string;
  label: string;
}

export const languages = {
  nl: 31,
  en: -1,
  fr: -2,
  de: -3,
};

export type Language = keyof typeof languages;

export const languageToCode = (locale: Language) => {
  return languages[locale];
};

export const fetchTranslations = ({ language = "en" }) => {
  if (process.env.REACT_APP_USE_MOCK_TRANSLATIONS === "yes") {
    return import(`../mocks/locales/${language}`).then((module) => {
      return module.default;
    });
  } else {
    return import(`../../locales/${language}`).then((module) => {
      return module.default;
    });
  }
};

export const pimLocalesDropdown: LocalesDropdown = {
  "31": {
    value: "nl",
    label: "Nederlands",
  },
  "-1": {
    value: "en",
    label: "English",
  },
  "-2": {
    value: "fr",
    label: "Français",
  },
  "-3": {
    value: "de",
    label: "Deutsch",
  },
};
