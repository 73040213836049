import { useRaffaella } from "@eriksdigital/raffaella";
import { ItemData } from "../../containers/ORingSelector/Results";
import { StockLevel } from "../../resourcesConfig";

export type PriceItem = {
  price: string;
  priceRaw: number;
  number: string;
};

const useItemWithPriceAndStock = (erpPartNumber: string) => {
  const [items] = useRaffaella("items");
  const [price] = useRaffaella("price");
  const [stockLevels] = useRaffaella("stockLevels");

  if (items?.isLoading || items?.error) {
    return null;
  }

  const itemPrice = price.data
    ? price.data.find(
        (priceItem: PriceItem) => priceItem.number === erpPartNumber
      )
    : { price: null, priceRaw: null, number: null };

  const itemStockLevel = stockLevels.data
    ? stockLevels.data.find(
        (stock: StockLevel) => stock.number === erpPartNumber
      )
    : { available: null, deliveryDate: null, number: null };

  const item: ItemData = {
    ...items?.data.items.find(
      (item: ItemData) => item.erpPartNumber === erpPartNumber
    ),
    isPriceLoading: price.isLoading,
    price: itemPrice?.price,
    priceRaw: itemPrice?.priceRaw,
    stockStatus: itemStockLevel,
  };
  return item;
};

export { useItemWithPriceAndStock };
