import React, { useState } from "react";
import {
  Modal,
  Button,
  Box,
  ReactSelect,
} from "@eriksdigital/atomic-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { LabelWrapper, ButtonWrapper } from "./styles";
import { SelectionOptions } from "../../containers/ORingSelector/ORingSelectorForm";

interface Props {
  onConfirm(value: String): void;
  onCancel: Function;
  value: string;
  open: boolean;
  sizeOptions: SelectionOptions[];
}

export const getSizeLabel = (
  sizeSelectionType: string,
  options: SelectionOptions[]
) => {
  return options.find((o) => o.value === sizeSelectionType)?.label || "";
};

export const getSearchCode = (
  sizeSelectionType: string,
  options: SelectionOptions[]
) => {
  return options.find((o) => o.value === sizeSelectionType)?.searchCode || "";
};

const SizeSelectionModal: React.FC<Props> = ({
  value,
  open,
  onCancel,
  onConfirm,
  sizeOptions,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const intl = useIntl();

  const handleSave = () => {
    onConfirm(selectedValue);
  };

  return (
    <>
      {open && (
        <Modal
          id="selection_size_modal"
          data-testid="selection_size_modal"
          withOutsideClose
          onClose={onCancel}
          maxWidth={520}
        >
          <div data-testid="selection_size_modal">
            <LabelWrapper>
              <Box>
                <FormattedMessage
                  id="modal-sizes.title"
                  defaultMessage="Currently the size input is set to {sizeType}"
                  values={{ sizeType: getSizeLabel(value, sizeOptions) }}
                  description="title of the sizes modal"
                />
              </Box>
              <Box marginBottom="sp16" fontWeight="bold">
                <FormattedMessage
                  id="modal-sizes.subtitle"
                  defaultMessage="Please note that selecting another “size according to” will erase all previous selections"
                  description="Size changing warning"
                />
              </Box>
            </LabelWrapper>
            <Box marginBottom="sp32">
              <label id="materialLabel">
                {intl.formatMessage({
                  id: "modal-sizes.dropdown",
                  defaultMessage: "Size according to:",
                  description: "Size selection label",
                })}
              </label>
              <ReactSelect
                id="sizeSelection"
                data-testid="sizeSelection"
                isClearable={false}
                isMulti={false}
                aria-labelledby="materialLabel"
                isSearchable={false}
                multiSelectedText="Selected"
                onChange={(selectedValue: SelectionOptions) =>
                  setSelectedValue(selectedValue.value)
                }
                options={sizeOptions}
                value={{
                  value: selectedValue,
                  label: getSizeLabel(selectedValue, sizeOptions),
                }}
                renderOptions={(option: SelectionOptions) => ({
                  label: option.label,
                  value: option.value,
                })}
              />
            </Box>
            <ButtonWrapper>
              <Button
                id="save-size-button"
                data-testid="saveSizeButton"
                stretch="auto"
                variant="primary"
                onClick={handleSave}
              >
                <FormattedMessage
                  id="modal-sizes.saveButton"
                  defaultMessage="Save"
                  description="Label for sizes save button"
                />
              </Button>
            </ButtonWrapper>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SizeSelectionModal;
