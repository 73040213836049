import React from "react";
import { Icon, Box } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { DividerWrapper, LabelWrapper, ShowMoreButton } from "./style";

interface Props {
  loadMore(limit: number): void;
  limit: number;
}

const ShowMore: React.FC<Props> = (props) => {
  const { loadMore, limit } = props;

  const handleLoadMore = (event: MouseEvent) => {
    loadMore(limit);
  };

  return (
    <Box direction="row" display="flex" alignItems="center" width="500px">
      <DividerWrapper />
      <ShowMoreButton
        data-testid="showMoreButton"
        display="flex"
        alignItems="center"
        onClick={handleLoadMore}
      >
        <LabelWrapper>
          <FormattedMessage
            id="results.moreResults"
            defaultMessage="Show more results"
            description="Show more results label"
          />
        </LabelWrapper>
        <Icon as={Icon.DownArrowIcon} size="sz16" />
      </ShowMoreButton>
    </Box>
  );
};

export default ShowMore;
