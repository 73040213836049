import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  StockIndicator,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import { DeliveryDate } from "./style";
import { useRaffaella } from "@eriksdigital/raffaella";
import { StockLevel } from "../../../../resourcesConfig";

export type statuses = {
  CompletelyOnStock: string;
  NothingOnStock: string;
  Loading: string;
  Unavailable: string;
};

const statusesType = {
  CompletelyOnStock: {
    status: "inStock",
    message: (
      <FormattedMessage
        id="stock-info.CompletelyOnStock"
        defaultMessage="In stock"
        description="Stock availability - completely on stock"
      />
    ),
  },
  NothingOnStock: {
    status: "outOfStock",
    message: (
      <FormattedMessage
        id="stock-info.NothingOnStock"
        defaultMessage="Out of stock"
        description="Stock availability - out of stock"
      />
    ),
  },
  Loading: {
    status: "loading",
    message: (
      <FormattedMessage
        id="stock-info.loading"
        defaultMessage="Loading stock information"
        description="Stock availability - loading"
      />
    ),
  },
  Unavailable: {
    status: "infoUnavailable",
    message: (
      <FormattedMessage
        id="stock-info.unavailable"
        defaultMessage="Stock information unavailable"
        description="Stock availability - unavailable"
      />
    ),
  },
};

export const StockInformation = ({
  erpPartNumber,
}: {
  erpPartNumber: string;
}) => {
  // todo set unavailable in the transform dto function
  const quantity = 0;
  const [stockLevels, makeResourceCall] = useRaffaella("stockLevels");
  const { erpSystem } = useContext(LanguageContext) as { erpSystem: any };

  useEffect(() => {
    if (makeResourceCall) {
      makeResourceCall({
        apiKey: "stockLevels",
        reqData: {
          erpSystem: erpSystem.toUpperCase(),
          itemsNumbers: erpPartNumber,
        },
        isLoadMore: true,
      });
    }
  }, [erpPartNumber, erpSystem, makeResourceCall]);

  const { data, isLoading } = stockLevels;

  const stockLevel = data
    ? data.find((stockLevel: StockLevel) => stockLevel.number === erpPartNumber)
    : {};

  const status: keyof statuses = isLoading ? "Loading" : stockLevel?.available;

  if (!!stockLevels.error) {
    return (
      <StockIndicator
        data-testid="o-ring-unavailable-stock-indicator"
        quantity={quantity}
        status={statusesType["Unavailable"]?.status}
        stockMessage={statusesType["Unavailable"]?.message}
      />
    );
  }

  return (
    !!status &&
    (status === "NothingOnStock" ? (
      <DeliveryDate>
        <FormattedMessage
          id="stock-info.deliveryDate"
          defaultMessage="Estimated delivery date"
          description=""
        />{" "}
        {stockLevel?.deliveryDate}
      </DeliveryDate>
    ) : (
      <StockIndicator
        data-testid={`o-ring-${statusesType[status]?.status}-stock-indicator`}
        quantity={quantity}
        status={statusesType[status]?.status}
        stockMessage={statusesType[status]?.message}
      />
    ))
  );
};
