import React, { useContext, Fragment, useEffect } from "react";
import {
  Box,
  LanguageContext,
  Link,
  LoadingIndicator,
} from "@eriksdigital/atomic-ui/components";
import { useIntl, FormattedMessage } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import {
  Wrapper,
  Title,
  EriksNumber,
  TextWrapper,
  PriceBlock,
  NoPriceMessage,
  ItemDescription,
  ItemPrice,
  ItemInventory,
  ItemStock,
  TemperatureInfo,
  PriceWrapper,
  IconImage,
  ImageWrapper,
} from "./styles";
import { BREAKPOINTS_LABELS, useMedia } from "../../../../utils/useMedia";
import { StockInformation } from "../StockInformation";
import { AddToCart } from "../AddToCart";
import { Attribute, Media } from "../index";
import { useRaffaella } from "@eriksdigital/raffaella";
import { useItemWithPriceAndStock } from "../../../../hooks/useItemWithPriceAndStock/useItemWithPriceAndStock";
import { languageToCode } from "../../../../utils/languageToCode";

interface Props {
  position: number;
  erpPartNumber: string;
}

const Item: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { position, erpPartNumber } = props;
  const { language, erpSystem } = useContext(LanguageContext) as {
    language: any;
    erpSystem: any;
  };
  const [user, makeResourceCall] = useRaffaella("user");
  const itemResource = useItemWithPriceAndStock(erpPartNumber);
  const isTablet = useMedia(BREAKPOINTS_LABELS.tabletLandscape);
  const { sendSelectItemEvent } = useContext(GTMContext);

  useEffect(() => {
    if (makeResourceCall && !user.isLoading && user.data?.loggedIn) {
      makeResourceCall({
        apiKey: "price",
        reqData: {
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_ORI",
          itemsNumbers: erpPartNumber,
          languageId: languageToCode(language),
        },
        isLoadMore: true,
      });
    }
  }, [makeResourceCall, erpSystem, user, language, erpPartNumber]);

  if (itemResource === null) {
    return null;
  }

  const priceInformation = () => {
    if (itemResource.isPriceLoading) {
      return <LoadingIndicator />;
    }
    if (
      itemResource.priceRaw === null ||
      (itemResource.priceRaw && itemResource.priceRaw < 0.00002)
    ) {
      return (
        <NoPriceMessage>
          <FormattedMessage
            id="results.noPriceMessage"
            defaultMessage="No price can be calculated for this item, please reach out to your contact person"
            description="Price unit label when price is unavailable"
          />
        </NoPriceMessage>
      );
    }
    return (
      <Fragment>
        <PriceWrapper
          data-testid="price"
          currentPrice={itemResource.price}
          itemUnit=""
          perUnitLabel={intl.formatMessage({
            id: "results.priceLabel",
            defaultMessage: "Price (per piece)",
            description: "Price unit label",
          })}
          variant="medium"
        />
        {isTablet && user.data?.loggedIn && (
          <Box>
            {itemResource && (
              <AddToCart
                data={itemResource}
                position={position}
                key={itemResource.erpPartNumber}
              />
            )}
          </Box>
        )}
      </Fragment>
    );
  };

  const getMediasFromProduct = (medias?: Media[]) => {
    return medias?.find(
      (media) =>
        media.mediaType.toLowerCase().includes("Product image".toLowerCase()) ||
        media.mediaType.toLowerCase().includes("Item image".toLowerCase())
    );
  };

  const getImageCertificationsFromProduct = (medias?: Media[]) => {
    return medias?.filter((media) =>
      media.mediaType.toLowerCase().includes("Product Icon".toLowerCase())
    );
  };

  const getTemperatureRange = (attributes: Attribute[] | undefined) => {
    const temperatureString = intl.formatMessage({
      id: "feature.temperatureRange",
      defaultMessage: "Temperature range",
      description: "Temperature range",
    });
    const temperatureRangeObject = attributes?.find(
      (attribute) => attribute.featureName === temperatureString
    );

    if (!temperatureRangeObject) {
      return "";
    }

    return `${temperatureRangeObject.featureName} (°C) ${temperatureRangeObject.value}`;
  };

  const handleOnClickItem = (event: MouseEvent) => {
    event.preventDefault();
    sendSelectItemEvent &&
      sendSelectItemEvent({
        itemName: itemResource.description,
        price: itemResource.priceRaw,
        itemId: itemResource.partNumber,
        itemBrand: itemResource.brand,
        itemStockStatus: itemResource.stockStatus.available,
        index: position,
        materialNumber: itemResource.erpPartNumber,
        isLoggedIn: user.data?.loggedIn,
      });
    window.open(itemResource.itemLink, "_blank");
  };

  return (
    <Wrapper>
      <ItemDescription>
        <Box width="100px">
          <ImageWrapper
            data-testid="productImage"
            alt="product image"
            src={getMediasFromProduct(itemResource.medias)?.fullImage || ""}
          />
        </Box>
        <TextWrapper>
          <Link
            data-testid={`oring-result-${itemResource.erpPartNumber}-link`}
            href={itemResource.itemLink}
            onClick={handleOnClickItem}
          >
            <Title data-testid="description">{itemResource.description}</Title>
          </Link>
          <Box marginTop="sp12">
            <EriksNumber data-testid="basicItemNumber">
              <FormattedMessage
                id="results.eriksNumber"
                defaultMessage="ERIKS Item:"
                description="article number label"
              />{" "}
              {itemResource.erpPartNumber}
            </EriksNumber>
            <TemperatureInfo
              data-testid={`oring-result-${itemResource.erpPartNumber}-temperature`}
            >
              {getTemperatureRange(itemResource.attributes)}
            </TemperatureInfo>
            <Box
              data-testid={`oring-result-${itemResource.erpPartNumber}-certificates`}
            >
              {getImageCertificationsFromProduct(itemResource.medias)?.map(
                (c: Media) => (
                  <IconImage
                    data-testid={`certificate-${c.mediaType}`}
                    key={c.mediaType}
                    src={c?.fullImage || ""}
                    alt=""
                  />
                )
              )}
            </Box>
          </Box>
        </TextWrapper>
      </ItemDescription>
      <ItemInventory>
        <ItemStock>
          <StockInformation erpPartNumber={erpPartNumber} />
        </ItemStock>
        <ItemPrice>
          {!user.data?.loggedIn ? (
            <PriceBlock>
              <Box>
                <Link
                  data-testid="loginForPrices"
                  alt="login for prices"
                  href={user.data?.loginUrl}
                >
                  <FormattedMessage
                    id="login.priceLogin"
                    defaultMessage="Please login to see prices"
                    description="Price ewhen user is not logged in"
                  />
                </Link>
              </Box>
            </PriceBlock>
          ) : (
            priceInformation()
          )}
          {!isTablet &&
            user.data?.loggedIn &&
            itemResource.priceRaw !== null && (
              <Box width="100%">
                {itemResource && (
                  <AddToCart
                    data={itemResource}
                    position={position}
                    key={itemResource.erpPartNumber}
                  />
                )}
              </Box>
            )}
        </ItemPrice>
      </ItemInventory>
    </Wrapper>
  );
};

export default Item;
