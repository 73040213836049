import React from "react";
import { Button } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";

const LogInStatus = (props: any) => {
  const { isGuestUser, loginUrl, loggedIn } = props.user;

  return (
    <>
      {!isGuestUser && loggedIn && (
        <FormattedMessage
          id="login-status.logged-in"
          defaultMessage="Logged in"
          description="status of loged in user"
        />
      )}
      {isGuestUser && !loggedIn && (
        <Button variant="ghost" id="login" as="a" href={loginUrl}>
          <FormattedMessage
            id="login-status.go-to-login"
            defaultMessage="Login for prices"
            description="Login for prices"
          />
        </Button>
      )}
    </>
  );
};

export default LogInStatus;
